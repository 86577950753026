<template>
  <div class="row no-gutters mt-5 alina-form p-5">
    <div class="col">
      <h3>
        Официальнй сайт TCH "ТСЖ Миронова 45А"
      </h3>
      <p>
        394005, Воронежская область, город Воронеж, улица Миронова 45 А
      </p>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {}
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
